import {
  CorvidSDKPropsFactory,
  ICleanedCorvidEvent,
  CorvidMouseEventHandler,
} from '@wix/editor-elements-types';
import {
  createCompSchemaValidator,
  convertToCorvidMouseEvent,
} from '@wix/editor-elements-corvid-utils';
import { MouseEventHandler } from 'react';

export interface IClickPropSDKProps {}

export interface IClickPropWithUpdateHandlerSDKProps
  extends IClickPropSDKProps {
  hasPlatformClickHandler: boolean;
}

export interface IClickPropSDK {
  onClick: (handler: CorvidMouseEventHandler) => void;
  onDblClick: (handler: CorvidMouseEventHandler) => void;
}

export interface IClickPropsSDKActions {
  onClick?: MouseEventHandler;
  onDblClick?: MouseEventHandler;
}

export const clickPropsSDKFactoryWithUpdatePlatformHandler: CorvidSDKPropsFactory<
  IClickPropWithUpdateHandlerSDKProps,
  IClickPropSDK
> = api => {
  const clickPropsApi = clickPropsSDKFactory(api);
  const { setProps, props } = api;

  return {
    ...clickPropsApi,
    onClick: handler => {
      clickPropsApi.onClick(handler);
      if (!props.hasPlatformClickHandler) {
        setProps({
          hasPlatformClickHandler: true,
        });
      }
    },
  };
};

export const clickPropsSDKFactory: CorvidSDKPropsFactory<
  IClickPropSDKProps,
  IClickPropSDK
> = ({ registerEvent, metaData, getSdkInstance }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  return {
    onClick(handler) {
      if (!functionValidator(handler, 'onClick')) {
        return getSdkInstance();
      }

      registerEvent<ICleanedCorvidEvent<React.MouseEvent>>('onClick', event => {
        const cleanedEvent = convertToCorvidMouseEvent(event);

        handler(cleanedEvent);
      });

      return getSdkInstance();
    },
    onDblClick(handler) {
      if (!functionValidator(handler, 'onDblClick')) {
        return getSdkInstance();
      }

      registerEvent<ICleanedCorvidEvent<React.MouseEvent>>(
        'onDblClick',
        event => {
          const cleanedEvent = convertToCorvidMouseEvent(event);

          handler(cleanedEvent);
        },
      );

      return getSdkInstance();
    },
  };
};
