import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getColorValidator, getRGBAWarning } from './validation';
import { cssVars } from './constants';
import { getScopedVar, isValidRGBA, rgbaToRGBCast } from './styleUtils';
import { IStylePropsSDKOptions } from './types';

export type IBackgroundColorSDK = {
  backgroundColor: string;
};

export const createBackgroundColorPropsSDKFactory = (
  options?: IStylePropsSDKOptions,
) => {
  const cssRule = getScopedVar({
    name: cssVars.backgroundColor,
    prefix: options?.prefix,
  });

  const _backgroundColorPropsSDKFactory: CorvidSDKPropsFactory<
    IBackgroundColorSDK,
    { backgroundColor?: string | null }
  > = ({ setStyles }) => {
    let backgroundColorState = options?.withoutDefaultValue ? undefined : '';
    return {
      set backgroundColor(backgroundColor) {
        const value =
          backgroundColor &&
          options &&
          !options.supportOpacity &&
          isValidRGBA(backgroundColor)
            ? rgbaToRGBCast(backgroundColor)
            : backgroundColor;
        backgroundColorState = value;
        setStyles({
          [cssRule]: value,
        });
      },
      get backgroundColor() {
        return backgroundColorState;
      },
    };
  };

  return withValidation(
    _backgroundColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        backgroundColor: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      backgroundColor:
        options && !options.supportOpacity
          ? [
              getColorValidator('backgroundColor', 'rgbColor', true),
              getRGBAWarning('backgroundColor'),
            ]
          : [getColorValidator('backgroundColor', 'rgbaColor')],
    },
  );
};
