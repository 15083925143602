import {
  composeSDKFactories,
  withValidation,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';
import {
  ICaptchaProps,
  ICaptchaSDK,
  ICaptchaOwnSDKFactory,
  CaptchaEventHandler,
} from '../Captcha.types';
import {
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const _ownSDKFactory: ICaptchaOwnSDKFactory = api => {
  const { props, compRef, registerEvent, metaData, getSdkInstance } = api;
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  return {
    blur() {},
    get token() {
      return props.token;
    },
    reset() {
      compRef.reset();
    },
    onError(handler: CaptchaEventHandler) {
      if (!functionValidator(handler, 'onError')) {
        return getSdkInstance();
      }
      registerEvent('onError', handler);
      return getSdkInstance();
    },
    onTimeout(handler: CaptchaEventHandler) {
      if (!functionValidator(handler, 'onTimeout')) {
        return getSdkInstance();
      }
      registerEvent('onTimeout', handler);
      return getSdkInstance();
    },
    onVerified(handler: CaptchaEventHandler) {
      if (!functionValidator(handler, 'onVerified')) {
        return getSdkInstance();
      }
      registerEvent('onVerified', handler);
      return getSdkInstance();
    },
    toJSON() {
      const { token } = props;
      return {
        ...toJSONBase(metaData),
        token,
      };
    },
  };
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {},
});

export const sdk = composeSDKFactories<ICaptchaProps, ICaptchaSDK>(
  elementPropsSDKFactory,
  focusPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  ownSDKFactory,
);
