import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { CorvidTypes } from '@wix/editor-elements-types';
import { tpaGalleryPropsSDKFactory } from '../../TPAGallery/corvid/TPAGallerySDK';
import { tpaGalleryCurrentItemSDKFactory } from '../../TPAGallery/corvid/TPAGalleryCurrentItemSDK';
import { tpaGalleryNavigationButtonsSDKFactory } from '../../TPAGallery/corvid/TPAGalleryNavigationButtonsSDK';
import {
  TpaGallerySDK,
  TpaGalleryProps,
  TpaGalleryCapabilitiesSDK,
} from '../../TPAGallery/TPAGallery.types';

export const ownSDKFactory: CorvidTypes.CorvidSDKFactory<TpaGalleryCapabilitiesSDK> = () => ({
  get galleryCapabilities() {
    return {
      isPlayable: true,
      hasCurrentItem: true,
      hasNavigationButtons: true,
      supportsAllMediaTypes: false,
      isAnimatable: true,
    };
  },
});

export const sdk = composeSDKFactories<TpaGalleryProps, TpaGallerySDK>(
  tpaGalleryPropsSDKFactory,
  tpaGalleryCurrentItemSDKFactory,
  tpaGalleryNavigationButtonsSDKFactory,
  ownSDKFactory,
);
