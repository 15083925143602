import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IMediaContainerSDK,
  IMediaContainerSDKFactory,
  MediaContainerCompProps,
  IMediaContainerOwnSDKFactory,
} from '../MediaContainer.types';
import { backgroundPropsSDKFactory } from '../../../core/corvid/props-factories/backgroundPropsSDKFactory';
import {
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories/elementPropsSDKFactory';
import {
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  hiddenPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const type = '$w.Container';

const mediaContainerSDKFactory: IMediaContainerOwnSDKFactory = ({
  metaData,
}) => {
  return {
    get type() {
      return type;
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type,
      };
    },
  };
};

export const sdk: IMediaContainerSDKFactory = composeSDKFactories<
  MediaContainerCompProps,
  IMediaContainerSDK
>(
  mediaContainerSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  backgroundPropsSDKFactory,
);
