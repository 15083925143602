import { parseMediaSrc } from '../../core/corvid/media/mediaSrcHandler';
import { GridImageData, GridRow } from './Grid.types';
import { DEFAULT_ROWS_PER_PAGE } from './constants';

type DataPath = string | Array<string>;

export const extractImageDataFromSrc = (src: string): GridImageData | null => {
  const { error, title, mediaId: uri, width, height } = parseMediaSrc(
    src,
    'image',
  );

  if (error || !uri) {
    return null;
  }

  return { uri, width, height, title, name: title };
};

export const getRowValue = (row: GridRow, dataPath?: DataPath): any => {
  if (!dataPath) {
    return undefined;
  }

  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let value = row;
  let index = 0;

  while (value && index < dataPathParts.length) {
    value = value[dataPathParts[index++]];
  }

  return index && index === dataPathParts.length ? value : undefined;
};

export const setRowValue = (row: GridRow, dataPath: DataPath, value: any) => {
  const dataPathParts = Array.isArray(dataPath)
    ? dataPath
    : dataPath.split('.');

  let valueRef = row;
  let index = 0;

  while (valueRef && index < dataPathParts.length - 1) {
    valueRef = valueRef[dataPathParts[index++]];
  }

  valueRef[dataPathParts[index]] = value;
};

export const getPageRowsRange = (
  page: number,
  rowsPerPage: number = DEFAULT_ROWS_PER_PAGE,
): [number, number] => {
  const startRow = (page - 1) * rowsPerPage;
  const endRow = startRow + rowsPerPage;
  return [startRow, endRow];
};

export const isValidDate = (date: Date) => !isNaN(date.getTime());

export const getLinkPropsPath = (linkPath: string) => `${linkPath}_linkProps`;
