import * as React from 'react';
import {
  CorvidSDKPropsFactory,
  CorvidKeyboardEventHandler,
  ICleanedCorvidEvent,
  CorvidEventHandler,
} from '@wix/editor-elements-types';
import {
  withValidation,
  createCompSchemaValidator,
  convertToCorvidKeyboardEvent,
  convertToCorvidEvent,
} from '@wix/editor-elements-corvid-utils';

export interface ITextInputPropSDKProps {
  placeholder?: string;
  maxLength?: number | null;
}

export interface ITextInputPropSDK {
  placeholder: string;
  maxLength?: number | null;
  onKeyPress: (handler: CorvidKeyboardEventHandler) => void;
  onInput: (handler: CorvidEventHandler) => void;
}

export interface ITextInputPropsSDKActions<T = Element> {
  onKeyPress: (event: React.KeyboardEvent<T>) => void;
  onInput: (event: React.FormEvent<T>) => void;
}

const _textInputPropsSDKFactory: CorvidSDKPropsFactory<
  ITextInputPropSDKProps,
  ITextInputPropSDK
> = ({ registerEvent, setProps, props, metaData, getSdkInstance }) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );

  return {
    onKeyPress(handler) {
      if (!functionValidator(handler, 'onKeyPress')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent<React.KeyboardEvent>>(
        'onKeyPress',
        event => {
          const convertedEvent = convertToCorvidKeyboardEvent(event);
          handler(convertedEvent);
        },
      );

      return getSdkInstance();
    },
    onInput(handler) {
      if (!functionValidator(handler, 'onInput')) {
        return getSdkInstance();
      }
      registerEvent<ICleanedCorvidEvent<React.FormEvent>>('onInput', event => {
        const convertedEvent = convertToCorvidEvent(event);
        handler(convertedEvent);
      });
      return getSdkInstance();
    },
    get placeholder() {
      return props.placeholder || '';
    },
    set placeholder(value) {
      const placeholder = value || '';
      setProps({ placeholder });
    },
    get maxLength() {
      return props.maxLength;
    },
    set maxLength(value) {
      const maxLength = value === null || value === undefined ? null : value;
      setProps({ maxLength });
    },
  };
};

export const textInputPropsSDKFactory = withValidation(
  _textInputPropsSDKFactory,
  {
    type: ['object'],
    properties: {
      placeholder: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
      maxLength: {
        type: ['integer', 'nil'],
        warnIfNil: true,
        minimum: 0,
      },
    },
  },
);
