export const SVG_FALLBACK_CONTENT = '<svg data-failed />';
export const SVG_TYPE_INLINE = 'inline';
export const SVG_TYPE_WIX_MEDIA = 'wixMedia';
export const SVG_TYPE_URL = 'url';

const WIX_MEDIA_PREFIX_REGEX = /^wix:vector:\/\/v1\//;

const WIX_MEDIA_REGEX = /^wix:vector:\/\/v1\/[0-9|a-z|_]+.svg/;

const isExternalUrl = (value: string) =>
  /^(?:(?:https?:)\/\/)(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?$/i.test(
    value,
  );

const isInlineSvg = (value: string) => value.startsWith('<svg');

const extractWixMediaUrl = (value: string) => {
  const [wixMediaUrl] = WIX_MEDIA_REGEX.exec(value) || [];
  return wixMediaUrl;
};

export const createSvgWixMediaUrl = (id: string, title: string) => {
  const titleSuffix = title ? encodeURIComponent(title) : '';
  return `wix:vector://v1/${id}/${titleSuffix}`;
};

export const resolveSvg = (src: string, baseSvgMediaUrl: string) => {
  const wixMediaUrl = extractWixMediaUrl(src);
  if (wixMediaUrl) {
    const svgId = wixMediaUrl.replace(WIX_MEDIA_PREFIX_REGEX, '');
    return {
      type: SVG_TYPE_WIX_MEDIA,
      data: `${baseSvgMediaUrl}${svgId}`,
    };
  } else if (isInlineSvg(src)) {
    return { type: SVG_TYPE_INLINE, data: src };
  }
  return { type: SVG_TYPE_URL, data: src };
};

export const fetchSvg = async (url: string) => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      return response.text();
    }
  } catch {
    /* do nothing */
  }
  return SVG_FALLBACK_CONTENT;
};

export const resolveAndFetchSvg = async (
  src: string,
  baseSvgMediaUrl: string,
) => {
  const { type, data } = resolveSvg(src, baseSvgMediaUrl);
  if (type === SVG_TYPE_INLINE) {
    return data;
  }
  return fetchSvg(data);
};

export const isFallbackSvg = (svg: string) => svg === SVG_FALLBACK_CONTENT;

export const isValidSvgValue = (value: string) =>
  Boolean(
    value &&
      (extractWixMediaUrl(value) || isExternalUrl(value) || isInlineSvg(value)),
  );
