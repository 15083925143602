import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getScopedVar, isValidRGBA, rgbaToRGBCast } from './styleUtils';
import { getColorValidator, getRGBAWarning } from './validation';
import { cssVars } from './constants';
import { IStylePropsSDKOptions } from './types';

export type ITextColorSDK = {
  color: string;
};

export const createTextColorPropsSDKFactory = (
  options?: Partial<IStylePropsSDKOptions>,
) => {
  const cssRule = getScopedVar({
    name: cssVars.textColor,
    prefix: options?.prefix,
  });

  const _textColorPropsSDKFactory: CorvidSDKPropsFactory<
    ITextColorSDK,
    { color?: string | null }
  > = ({ setStyles }) => {
    let textColorState = options?.withoutDefaultValue ? undefined : '';
    return {
      set color(color) {
        const value =
          color && isValidRGBA(color) ? rgbaToRGBCast(color) : color;
        textColorState = value;
        setStyles({
          [cssRule]: value,
        });
      },
      get color() {
        return textColorState;
      },
    };
  };
  return withValidation(
    _textColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        color: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      color: [
        getColorValidator('color', 'rgbColor', true),
        getRGBAWarning('color'),
      ],
    },
  );
};
