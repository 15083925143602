import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { SdkInstance } from '@wix/editor-elements-types';
import {
  backgroundPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import { componentType as columnType } from '../../Column/constants';
import { corvidName as type } from '../constants';
import {
  IStripColumnsContainerOwnSDKFactory,
  IStripColumnsContainerSDK,
  IStripColumnsContainerSDKFactory,
  StripColumnsContainerProps,
} from '../StripColumnsContainer.types';

const stripColumnsContainerSDKFactory: IStripColumnsContainerOwnSDKFactory = ({
  getSdkInstance,
  metaData,
}) => {
  return {
    get columns() {
      return getSdkInstance().children.filter((child: SdkInstance) =>
        child.type.includes(columnType),
      );
    },
    get type() {
      return type;
    },
    toJSON() {
      return {
        ...toJSONBase(metaData),
        type,
      };
    },
  };
};

export const sdk: IStripColumnsContainerSDKFactory = composeSDKFactories<
  StripColumnsContainerProps,
  IStripColumnsContainerSDK
>(
  elementPropsSDKFactory,
  stripColumnsContainerSDKFactory,
  backgroundPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  hiddenPropsSDKFactory,
);
