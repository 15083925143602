import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import { withValidation } from '@wix/editor-elements-corvid-utils';
import { getColorValidator, getRGBAWarning } from './validation';
import { getScopedVar, isValidRGBA, rgbaToRGBCast } from './styleUtils';
import { cssVars } from './constants';
import { IStylePropsSDKOptions } from './types';

export type IBorderColorSDK = {
  borderColor: string;
};

export const createBorderColorPropsSDKFactory = (
  options?: IStylePropsSDKOptions,
) => {
  const cssRule = getScopedVar({
    name: cssVars.borderColor,
    prefix: options?.prefix,
  });

  const _borderColorPropsSDKFactory: CorvidSDKPropsFactory<
    IBorderColorSDK,
    { borderColor?: string | null }
  > = ({ setStyles }) => {
    let borderColorState = options?.withoutDefaultValue ? undefined : '';
    return {
      set borderColor(borderColor) {
        const value =
          borderColor &&
          options &&
          !options.supportOpacity &&
          isValidRGBA(borderColor)
            ? rgbaToRGBCast(borderColor)
            : borderColor;
        borderColorState = value;
        setStyles({
          [cssRule]: value,
        });
      },
      get borderColor() {
        return borderColorState;
      },
    };
  };

  return withValidation(
    _borderColorPropsSDKFactory,
    {
      type: ['object'],
      properties: {
        borderColor: {
          type: ['string', 'nil'],
        },
      },
    },
    {
      borderColor:
        options && !options.supportOpacity
          ? [
              getColorValidator('borderColor', 'rgbColor', true),
              getRGBAWarning('borderColor'),
            ]
          : [getColorValidator('borderColor', 'rgbaColor')],
    },
  );
};
