import {
  withValidation,
  composeSDKFactories,
  createCompSchemaValidator,
} from '@wix/editor-elements-corvid-utils';

import {
  IHtmlComponentSDKFactory,
  IHtmlComponentProps,
  IHtmlComponentSDK,
} from '../HtmlComponent.types';
import {
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
} from '../../../core/corvid/props-factories';

export const _htmlComponentSdkFactory: IHtmlComponentSDKFactory = ({
  setProps,
  setStyles,
  registerEvent,
  props,
  compRef,
  metaData,
  getSdkInstance,
}) => {
  const functionValidator = (value: Function, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['function'],
      },
      setterName,
    );
  const messageValidator = (value: any, setterName: string) =>
    createCompSchemaValidator(metaData.role)(
      value,
      {
        type: ['string', 'function', 'number', 'boolean', 'array', 'object'],
      },
      setterName,
    );
  const _state = {
    hidden: metaData.hiddenOnLoad,
  };
  return {
    get scrolling() {
      return props.scrolling;
    },

    set scrolling(value) {
      setProps({ scrolling: value });
    },

    get src() {
      return props.url;
    },
    set src(value) {
      setProps({ url: value });
    },

    allowFullScreen: () => {
      setProps({ allow: 'fullscreen' });
      return getSdkInstance();
    },

    onMessage: cb => {
      if (!functionValidator(cb, 'onMessage')) {
        return getSdkInstance();
      }
      registerEvent('onMessage', cb);
      return getSdkInstance();
    },

    postMessage: message => {
      if (!messageValidator(message, 'postMessage')) {
        return getSdkInstance();
      }
      compRef.postMessage(message);
      return getSdkInstance();
    },

    /**
     * This here below is reimplementation of hiddenMixin
     * because iframe does not play well with visibility css rule
     */
    hide() {
      setStyles({ visibility: 'hidden', '--corvid-hidden-display': 'none' });
      _state.hidden = true;
      return Promise.resolve();
    },

    show() {
      setStyles({ visibility: null, '--corvid-hidden-display': null });
      _state.hidden = false;
      return Promise.resolve();
    },

    get hidden() {
      return Boolean(_state.hidden);
    },
  };
};

const htmlComponentSdkFactory = withValidation(_htmlComponentSdkFactory, {
  type: ['object'],
  properties: {
    src: { type: ['string'] },
    scrolling: { type: ['string'], enum: ['yes', 'no', 'auto'] },
    allowFullScreen: { type: ['function'] },
  },
});

export const sdk = composeSDKFactories<IHtmlComponentProps, IHtmlComponentSDK>(
  elementPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  htmlComponentSdkFactory,
);
