import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  ISlideShowGalleryOwnSDK,
  ISlideShowGalleryProps,
  ISlideShowGallerySDKFactory,
  SlideShowGallerySDKState,
} from '../SlideShowGallery.types';

const SlideShowGallerySDKFactory: ISlideShowGallerySDKFactory = ({
  registerEvent,
  props,
  compRef,
}) => {
  const _state: SlideShowGallerySDKState = {
    navigationEndCallbacks: [],
  };

  registerEvent('onNavigationEnd', () => {
    _state.navigationEndCallbacks.forEach(cb => cb());
    _state.navigationEndCallbacks = [];
  });

  const sdkProps = {
    next() {
      return new Promise<void>(resolve => {
        _state.navigationEndCallbacks.push(resolve);
        compRef.moveToNextItem();
      });
    },
    previous() {
      return new Promise<void>(resolve => {
        _state.navigationEndCallbacks.push(resolve);
        compRef.moveToPrevItem();
      });
    },
    get currentIndex() {
      return props.currentIndex;
    },
  };

  return sdkProps;
};

export const sdk = composeSDKFactories<
  ISlideShowGalleryProps,
  ISlideShowGalleryOwnSDK
>(SlideShowGallerySDKFactory);
